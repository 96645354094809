import dynamic from "next/dynamic";
import { Section } from "..";
import { HorizontalCardListWrapper } from "./styles";

const HorizontalCardListItem = dynamic(() =>
  import("../../elements/horizontal-card-list-item/horizontal-card-list-item")
);

export const HorizontalCardList = ({
  sectionTitle,
  sectionSubtitle,
  hideLineSeparation,
  cardItems,
  displaySetting
}) => {
  const hasCardItems = cardItems.length > 0;

  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      {hasCardItems && (
        <HorizontalCardListWrapper>
          {cardItems.map((card) => (
            <HorizontalCardListItem
              key={`horizontal-card-list-item-${card.id}`}
              {...card}
            />
          ))}
        </HorizontalCardListWrapper>
      )}
    </Section>
  );
};

export default HorizontalCardList;
